import Entry from "pages/Entry/Entry";
import Main from "pages/Main/Main";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";
import navMainImg from "assets/images/nav-images/home.png";
import navFriendsImg from "assets/images/nav-images/friends.png";
import navTasksImg from "assets/images/nav-images/tasks.png";
import navWalletImg from "assets/images/nav-images/wallet.png";
import navStoreImg from "assets/images/nav-images/store.png";
import Friends from "pages/Friends/Friends";
import Tasks from "pages/Tasks/Tasks";
import Wallet from "pages/Wallet/Wallet";
import Store from "pages/Store/Store";
type Route = {
  route: RouteObject;
  titleKey?: string;
  navBarIcon?: string;
};

const routes: Route[] = [
  {
    route: {
      path: "/",
      element: <Entry />,
    },
  },
  {
    route: {
      path: "/main",
      element: <Main />,
    },
    navBarIcon: navMainImg,
    titleKey: "Main",
  },

  {
    route: {
      path: "/friends",
      element: <Friends />,
    },
    navBarIcon: navFriendsImg,
    titleKey: "Friends",
  },

  {
    route: {
      path: "/tasks",
      element: <Tasks />,
    },
    navBarIcon: navTasksImg,
    titleKey: "Tasks",
  },

  {
    route: {
      path: "/wallet",
      element: <Wallet />,
    },
    navBarIcon: navWalletImg,
    titleKey: "Wallet",
  },

  {
    route: {
      path: "/store",
      element: <Store />,
    },
    navBarIcon: navStoreImg,
    titleKey: "Store",
  },
];
export default routes;
