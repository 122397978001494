import { THEME, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react'
import React, { useContext, useEffect } from 'react'
import Button from 'UI/Button'
import Icon from 'UI/Icon'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {}

const WalletTonConnect = (props: Props) => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    console.log("🚀 ~ WalletTonConnect ~ tonConnectUI:", tonConnectUI)
  
    const userFriendlyAddress = useTonAddress();
    const isWalletConnected = tonConnectUI.connected;
    const connectWallet = () => {
     try {
      if (isWalletConnected) {
        tg.showConfirm('Are you sure you want to disable your wallet?',(ok) => {
            if(ok) tonConnectUI.disconnect();
        })
      } else tonConnectUI.openModal();
     } catch (error) {
      console.log("🚀 ~ connectWal ~ error:", error)
      
     }
    };
  
    useEffect(() => {
      setOptions({
        uiPreferences: {
          theme: THEME.DARK,
        },
      });
    }, []);
  return (
    <Button className="user-wallet" onClick={connectWallet}>
        {isWalletConnected
        ? <>
        <p>{collapseAddress(userFriendlyAddress)}</p>
        <Icon icon='walletOn'/>
        </>
        : 
            <>
            <p>Add wallet</p>
            <Icon icon='addCircle'/>
            </>
        }
    </Button>
  )
}
const collapseAddress = (address:string) => `${address.slice(0,2)}...${address.slice(-4)}` 

export default WalletTonConnect