import {HTMLMotionProps, motion, Variants } from "framer-motion";
import {LegacyRef } from "react";
import { useNavigationType } from "react-router-dom";

interface Props extends HTMLMotionProps<"div"> {
  loading?: boolean;
  ref?:LegacyRef<HTMLDivElement>
  onAnimationEnd?: () => void;
}


const PageWrapper = (props: Props) => {
  const { loading, children, onAnimationEnd } = props;
  const distance = window.outerWidth / 2
  const navigationType = useNavigationType();
  const isGoingBack = navigationType === "POP";

  const variants:Variants = {
    enter: {
        x: !isGoingBack ? distance : -distance,
        // x: !isGoingBack ? '100%' : '-100%',
        opacity: 0
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: {
      position:'absolute',
      zIndex: 0,
        x: isGoingBack ? distance : -distance,
      // x: isGoingBack ? '100%' : '-100%',
      opacity: 0
    }
  };

  return (
    <motion.div
      ref={props.ref || null}
      onAnimationComplete={onAnimationEnd}
      id={window.location.pathname}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        ease:'circInOut',
        duration: 0.25,
      }}
      {...props}
      className={`${props.className} page-wrapper`}
    >
      {children}
    </motion.div>
  );
};

export default PageWrapper;
