function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Получаем последние две цифры года, месяц и день
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  
  // Форматируем в YY.MM.DD
  return `${year}.${month}.${day}`;
}

export default formatDate