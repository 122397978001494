import React from 'react'
import { Friendslist } from 'types/user-data'
import formatNumber from 'utils/format-number'
import coinImg from "assets/images/coin.png";
import Icon from 'UI/Icon';

type Props = {
  friend:Friendslist
}

const FriendCard = ({friend:{coins,name,photo,referrals_count,referrals_reward}}: Props) => {
  return (
    <li className="friend-card pink-opacity">
    <div className="friend-card-info">
      <img src={photo} alt="" className='friend-card-photo' />
      <div className="friend-card-info-text">
        <p>{name}</p>
        <div className="friend-card-info-stats">
          <Icon icon="friends"/>
          {formatNumber(referrals_count)}
          <img src={coinImg} className="coin" alt="" />
          +{formatNumber(referrals_reward)}
          <Icon icon="friendInfo"/>
        </div>
      </div>
    </div>
    <div className="friend-card-revenue">
      <img src={coinImg} alt="" />
      <h5>+{formatNumber(coins)}</h5>
    </div>
</li>
  )
}

export default FriendCard