import React, { useEffect, useState } from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import "./Entry.css";
import PageWrapper from "UI/PageWrapper";
import Icon from "UI/Icon";
import img2 from "assets/images/entry-bg.jpg"; // Предварительно загружаем это изображение
import img from "assets/images/bg.jpg";
import img3 from "assets/images/main-bg.jpg";
import img4 from "assets/images/ubder-construction.png";
import img5 from "assets/images/coin.png";
import img6 from "assets/images/store-bg.png";
import stages from "./stages";
import { useNavigate } from "react-router-dom";

type Props = {};

const Entry = (props: Props) => {
  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false); // Отслеживаем загрузку всех изображений
  const [isInitialImageLoaded, setIsInitialImageLoaded] = useState(false); // Для загрузки первого изображения
  const navigate = useNavigate();

  // Прелоад только для первого изображения
  const preloadInitialImage = (src: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        resolve();
        setIsInitialImageLoaded(true);
      };
      img.onerror = () => reject();
    });
  };

  // Прелоад для остальных изображений
  const preloadRemainingImages = (sources: string[]): Promise<void> => {
    return Promise.all(
      sources.map((src) => {
        return new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });
      })
    ).then(() => {
      setIsLoaded(true); // Устанавливаем флаг, когда все изображения загружены
    });
  };

  useEffect(() => {
    // Сначала загружаем первое изображение
    preloadInitialImage(img2);
  }, []);

  useEffect(() => {
    if (isInitialImageLoaded) {
      // Начинаем прелоад остальных изображений, когда первое изображение загружено
      preloadRemainingImages([img, img3, img4, img5, img6]);
    }
  }, [isInitialImageLoaded]);

  useEffect(() => {
    if (!isInitialImageLoaded || progress >= 100) {
      if (progress >= 100) setTimeout(() => navigate("/main"), 500);
      return;
    }

    const timer = setTimeout(() => {
      setProgress((prev) => {
        if (!isLoaded && prev >= 90) return 90; // Останавливаем прогресс на 90%, если медиа еще не загружены
        return prev + 1;
      });
    }, 50);

    return () => clearTimeout(timer);
  }, [progress, isInitialImageLoaded, isLoaded]);

  const loadingScreenProps: HTMLMotionProps<"div"> = {
    exit: {
      opacity: 0,
      transition: { duration: 2 },
    },
  };

  return (
    <PageWrapper className="entry-page">
      <AnimatePresence>
        {!isInitialImageLoaded && (
          <motion.div {...loadingScreenProps} className="entry-loading-screen"></motion.div>
        )}
      </AnimatePresence>
      {isInitialImageLoaded && (
        <>
          <ul className="entry-loading-news">
            {stages.map(({ quarter, text, year, done }, index) => (
              progress >= (index + 1) * 20 && (
                <motion.li
                  className="entry-loading-news-block pink-opacity"
                  key={index}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {done && <Icon icon="entryNewsDone" />}
                  <div className="entry-loading-news-quarter">
                    <h4>{quarter}</h4>
                    <p>{year}</p>
                  </div>
                  <p>{text}</p>
                </motion.li>
              )
            ))}
          </ul>
          <div className="entry-loading-progress">
            <h3 className="text-light">{progress}%</h3>
            <div className="entry-loading-progress-bar-container pink-opacity">
              <div
                className="entry-loading-progress-bar"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default Entry;
