import React from 'react'
import PageWrapper from 'UI/PageWrapper'
import temp from 'assets/images/ubder-construction.png'
import './Store.css'
type Props = {}

const Store = (props: Props) => {
  return (
    <PageWrapper className='store-page '>
      <h1>Coming soon...</h1>
    </PageWrapper>
  )
}

export default Store