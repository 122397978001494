import { createContext, ReactNode, useEffect, useRef, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import tg from "utils/tg";
const errors = {
    "appError":"It seems that some kind of error has occurred, you need to go back to the bot and send /start",
    "quit":"Back to the bot"
}
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [user, setUser] = useState<UserData | null>(null)
    // const [products, setProducts] = useState<Product[] | null>(null)
    const [showGameRules, setshowGameRules] = useState(false)
    // const [modal, setModal] = useState<ModalProps | null>(null)
    const interval = useRef<NodeJS.Timer | null>(null)

    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result) setUser(result)
        if(result?.error) {
            tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
                tg.close()
                tg.openTelegramLink(inviteLink)
            })
        }
    }

    // const updateProducts = async () => {
    //     const result = await getProducts()
    //     if (result) setProducts(result)
    // }

    const AppContextValue:AppContextType = {
        user,
        updateUserInfo,
        setUser,
        showGameRules,
        setshowGameRules,
        // modal,setModal,
        // products,updateProducts
    }


    useEffect(() => {
        updateUserInfo()
        // updateProducts()
    }, [])
    
    return {AppContext,AppContextValue}
}