import React, { SVGProps } from 'react'
export type IconType = keyof typeof icons
interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
   
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    ),
    entryNewsDone:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_8026_758)">
        <rect width="30" height="30" rx="15" fill="white"/>
        <path d="M23.2171 9.49317C23.5729 10.4137 23.074 11.0439 22.1084 11.6647C21.3292 12.1655 20.3365 12.7082 19.2847 13.6359C18.2536 14.5453 17.2473 15.6406 16.353 16.7188C15.4624 17.7925 14.7054 18.8217 14.1705 19.5838C13.8248 20.0762 13.3425 20.8108 13.3425 20.8108C13.0023 21.349 12.3908 21.6747 11.735 21.6665C11.0791 21.6584 10.4767 21.3177 10.1513 20.771C9.31936 19.3734 8.67743 18.8215 8.38238 18.6232C7.59294 18.0927 6.66663 18.0143 6.66663 16.778C6.66663 15.7962 7.4957 15.0003 8.51843 15.0003C9.24048 15.027 9.91161 15.3109 10.5063 15.7105C10.8864 15.966 11.289 16.3038 11.7079 16.7485C12.1994 16.078 12.7918 15.3045 13.4558 14.504C14.4201 13.3415 15.5584 12.0943 16.779 11.0177C17.979 9.95933 19.3663 8.96867 20.8368 8.44488C21.7957 8.10335 22.8614 8.57269 23.2171 9.49317Z" fill="#0BC1A2"/>
        </g>
        <defs>
        <filter id="filter0_b_8026_758" x="-10" y="-10" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8026_758"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8026_758" result="shape"/>
        </filter>
        </defs>
        </svg>
    ),
    friends:(
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1181 10.5C12.5552 10.5 12.9029 10.225 13.215 9.84031C13.8541 9.05298 12.8049 8.4238 12.4047 8.11568C11.9979 7.80243 11.5438 7.62498 11.0833 7.58333M10.4999 6.41667C11.3054 6.41667 11.9583 5.76375 11.9583 4.95833C11.9583 4.15292 11.3054 3.5 10.4999 3.5" stroke="white" strokeLinecap="round"/>
        <path d="M1.88178 10.5C1.44468 10.5 1.09702 10.225 0.784864 9.84031C0.145851 9.05298 1.19502 8.4238 1.59517 8.11568C2.00195 7.80243 2.45614 7.62498 2.91663 7.58333M3.2083 6.41667C2.40289 6.41667 1.74997 5.76375 1.74997 4.95833C1.74997 4.15292 2.40289 3.5 3.2083 3.5" stroke="white" strokeLinecap="round"/>
        <path d="M4.71548 8.81483C4.11944 9.18338 2.55667 9.93593 3.5085 10.8776C3.97347 11.3376 4.49131 11.6666 5.14237 11.6666H8.85751C9.50856 11.6666 10.0264 11.3376 10.4914 10.8776C11.4432 9.93593 9.88044 9.18338 9.28439 8.81483C7.88666 7.95056 6.11316 7.95056 4.71548 8.81483Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.04161 4.37504C9.04161 5.50262 8.12753 6.41671 6.99995 6.41671C5.87236 6.41671 4.95827 5.50262 4.95827 4.37504C4.95827 3.24746 5.87236 2.33337 6.99995 2.33337C8.12753 2.33337 9.04161 3.24746 9.04161 4.37504Z" stroke="white"/>
        </svg>
    ),
    friendInfo:(
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5" clipPath="url(#clip0_10001_76)">
        <path d="M12.8333 6.7472C12.8333 9.82907 10.2213 12.3278 7 12.3278C6.62124 12.3283 6.24353 12.2932 5.87148 12.2234C5.60369 12.1731 5.46979 12.1479 5.37631 12.1622C5.28282 12.1765 5.15036 12.2469 4.88541 12.3879C4.13592 12.7865 3.26197 12.9272 2.42148 12.7709C2.74093 12.3779 2.9591 11.9065 3.05537 11.4011C3.11371 11.0919 2.96917 10.7916 2.75269 10.5718C1.76944 9.57333 1.16667 8.22793 1.16667 6.7472C1.16667 3.66537 3.77871 1.16663 7 1.16663C10.2213 1.16663 12.8333 3.66537 12.8333 6.7472Z" stroke="white" strokeLinejoin="round"/>
        <path d="M5.83333 5.74355C5.83333 5.14878 6.35565 4.66663 7 4.66663C7.64435 4.66663 8.16667 5.14878 8.16667 5.74355C8.16667 5.95795 8.09883 6.15768 7.98181 6.32551C7.63315 6.82566 7 7.30265 7 7.89742V8.16663" stroke="white" strokeLinecap="round"/>
        <path d="M7 9.625H7.00525" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_10001_76">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    ),
    copy:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9637 8.98209C16.9613 6.03194 16.9167 4.50384 16.0578 3.45753C15.892 3.25546 15.7067 3.07019 15.5047 2.90436C14.4008 1.99854 12.7609 1.99854 9.48087 1.99854C6.20089 1.99854 4.5609 1.99854 3.45708 2.90436C3.255 3.07018 3.06971 3.25546 2.90387 3.45753C1.99799 4.56128 1.99799 6.20116 1.99799 9.48091C1.99799 12.7607 1.99799 14.4005 2.90387 15.5043C3.0697 15.7063 3.255 15.8916 3.45708 16.0574C4.50346 16.9162 6.03167 16.9608 8.98201 16.9632" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0283 9.02455L16.994 8.98193M14.0143 22.0013L16.9799 21.9586M21.9716 14.0221L21.9436 16.9818M9.01033 14.0357L8.98236 16.9953M11.4873 9.02455C10.6545 9.17371 9.31781 9.32713 9.01033 11.0488M19.4946 21.9586C20.3296 21.8223 21.6685 21.6894 22.0025 19.9726M19.4946 9.02455C20.3274 9.17371 21.6641 9.32713 21.9716 11.0488M11.5 21.9573C10.6672 21.8086 9.33039 21.6559 9.02197 19.9344" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    done:(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M5 14L8.23309 16.4248C8.66178 16.7463 9.26772 16.6728 9.60705 16.2581L18 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    ),
    ruby:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.53501 3.25C6.24686 3.25009 5.96319 3.32134 5.70919 3.45741C5.45519 3.59348 5.23873 3.79017 5.07901 4.03L1.49001 9.412C1.27101 9.742 1.24501 10.164 1.42201 10.518C3.38719 14.4454 6.11651 17.941 9.45001 20.8L11.2 22.301C11.4229 22.4921 11.7069 22.5971 12.0005 22.5971C12.2941 22.5971 12.5781 22.4921 12.801 22.301L14.551 20.801C17.885 17.9418 20.6146 14.4458 22.58 10.518C22.757 10.164 22.73 9.742 22.511 9.412L18.92 4.03C18.7603 3.79049 18.5439 3.59409 18.2901 3.4582C18.0363 3.32232 17.7529 3.25115 17.465 3.251L6.53501 3.25ZM6.32701 4.861C6.34988 4.82682 6.38082 4.7988 6.4171 4.77944C6.45339 4.76008 6.49388 4.74997 6.53501 4.75H8.87501L6.96001 9.346C6.91746 9.45162 6.884 9.56068 6.86001 9.672C6.15575 9.61947 5.45233 9.55613 4.75001 9.482L3.34501 9.335L6.32701 4.861ZM3.28001 10.836C5.07531 14.1275 7.4438 17.072 10.274 19.531L7.14901 11.197C6.29641 11.1384 5.44494 11.0644 4.59501 10.975L3.28001 10.836ZM8.78601 11.29L12 19.864L15.215 11.29C13.0728 11.3871 10.9272 11.3871 8.78501 11.29M16.852 11.197L13.727 19.531C16.5572 17.072 18.9257 14.1275 20.721 10.836L19.406 10.975C18.556 11.0637 17.7047 11.1377 16.852 11.197ZM20.655 9.335L19.249 9.483C18.5467 9.55713 17.8433 9.62047 17.139 9.673C17.1154 9.56138 17.0823 9.45198 17.04 9.346L15.125 4.75H17.465C17.5061 4.74997 17.5466 4.76008 17.5829 4.77944C17.6192 4.7988 17.6501 4.82682 17.673 4.861L20.655 9.335ZM15.592 9.77C13.198 9.894 10.8033 9.894 8.40801 9.77L10.5 4.75H13.5L15.592 9.77Z" fill="white"/>
        </svg>
    ),
    star:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.793 1.75949C13.059 0.274344 10.941 0.274344 10.207 1.75949L8.08618 6.0507C7.79491 6.64005 7.23261 7.04862 6.58211 7.14358L1.84023 7.83581C0.199618 8.07531 -0.453882 10.0923 0.734565 11.2484L2.68802 13.1487C2.89387 13.349 3.17587 13.451 3.4622 13.429L8.50912 13.0397C9.56156 12.9586 10.0055 14.3518 9.10013 14.8945L4.90807 17.4072C4.6581 17.557 4.48593 17.8084 4.43661 18.0957L3.92772 21.0591C3.64719 22.6926 5.36144 23.9389 6.82885 23.1682L11.07 20.9406C11.6523 20.6348 12.3477 20.6348 12.93 20.9406L17.1712 23.1682C18.6386 23.9389 20.3528 22.6926 20.0723 21.0591L19.264 16.3522C19.1524 15.7025 19.3681 15.0397 19.8406 14.5801L23.2654 11.2484C24.4539 10.0923 23.8004 8.07531 22.1598 7.83581L17.4179 7.14358C16.7674 7.04862 16.2051 6.64005 15.9138 6.0507L13.793 1.75949Z" fill="white"/>
        </svg>
    ),
    ton:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.624 6.68796L12.906 20.571C12.7455 20.8251 12.4938 21.0082 12.2026 21.0826C11.9114 21.157 11.6027 21.1171 11.34 20.971C11.1658 20.8734 11.0187 20.7338 10.912 20.565L2.365 6.68196C2.12554 6.29289 1.99915 5.84482 2 5.38796C2.0108 4.71348 2.28872 4.07083 2.77278 3.60102C3.25684 3.1312 3.9075 2.8726 4.582 2.88196H19.43C20.847 2.88196 22 3.99996 22 5.38196C22 5.84196 21.87 6.29396 21.623 6.68796M4.463 6.22396L10.823 16.029V5.23496H5.128C4.47 5.23496 4.175 5.67096 4.464 6.22396M13.176 16.029L19.536 6.22396C19.829 5.67096 19.529 5.23496 18.871 5.23496H13.176V16.029Z" fill="white"/>
        </svg>
    ),
    addCircle:(
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z" fill="#FFD335"/>
        <path d="M16.5 12V20M20.5 16H12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 16C6.5 21.5228 10.9771 26 16.5 26C22.0228 26 26.5 21.5228 26.5 16C26.5 10.4771 22.0228 6 16.5 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 12.5C7.36239 11.6706 7.8189 10.8917 8.35601 10.1768M10.6768 7.85598C11.3917 7.31888 12.1706 6.86239 13 6.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    arrowRight:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    receiveCircle:(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#0BC1A2"/>
        <path d="M16 23.5L16 13.5M16 23.5C16.7002 23.5 18.0085 21.5057 18.5 21M16 23.5C15.2998 23.5 13.9915 21.5057 13.5 21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 11.5C8 9.018 8.518 8.5 11 8.5L21 8.5C23.482 8.5 24 9.018 24 11.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    sendCircle:(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#DA4B66"/>
        <path d="M16 8.5V18.5M16 8.5C15.2998 8.5 13.9915 10.4943 13.5 11M16 8.5C16.7002 8.5 18.0085 10.4943 18.5 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 20.5C24 22.982 23.482 23.5 21 23.5H11C8.518 23.5 8 22.982 8 20.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    timer:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g id="Calendar / Timer">
        <path id="Vector" d="M12 13V9M21 6L19 4M10 2H14M12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13C20 17.4183 16.4183 21 12 21Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </svg>
    ),
    walletOn:(
        <svg width="43" height="26" viewBox="0 0 43 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 13C0.5 5.8203 6.3203 0 13.5 0H29.5C36.6797 0 42.5 5.8203 42.5 13C42.5 20.1797 36.6797 26 29.5 26H13.5C6.3203 26 0.5 20.1797 0.5 13Z" fill="#0BC1A2"/>
        <path d="M15.908 18.668C14.8907 18.668 13.9713 18.4487 13.15 18.01C12.338 17.5713 11.6987 16.9693 11.232 16.204C10.7747 15.4293 10.546 14.5613 10.546 13.6C10.546 12.6387 10.7747 11.7753 11.232 11.01C11.6987 10.2353 12.338 9.62867 13.15 9.19C13.9713 8.75133 14.8907 8.532 15.908 8.532C16.9253 8.532 17.84 8.75133 18.652 9.19C19.464 9.62867 20.1033 10.2353 20.57 11.01C21.0367 11.7753 21.27 12.6387 21.27 13.6C21.27 14.5613 21.0367 15.4293 20.57 16.204C20.1033 16.9693 19.464 17.5713 18.652 18.01C17.84 18.4487 16.9253 18.668 15.908 18.668ZM15.908 16.736C16.4867 16.736 17.0093 16.6053 17.476 16.344C17.9427 16.0733 18.3067 15.7 18.568 15.224C18.8387 14.748 18.974 14.2067 18.974 13.6C18.974 12.9933 18.8387 12.452 18.568 11.976C18.3067 11.5 17.9427 11.1313 17.476 10.87C17.0093 10.5993 16.4867 10.464 15.908 10.464C15.3293 10.464 14.8067 10.5993 14.34 10.87C13.8733 11.1313 13.5047 11.5 13.234 11.976C12.9727 12.452 12.842 12.9933 12.842 13.6C12.842 14.2067 12.9727 14.748 13.234 15.224C13.5047 15.7 13.8733 16.0733 14.34 16.344C14.8067 16.6053 15.3293 16.736 15.908 16.736ZM28.1685 8.532C29.3352 8.532 30.2452 8.90067 30.8985 9.638C31.5518 10.366 31.8785 11.4067 31.8785 12.76V18.5H29.6105V12.83C29.6105 12.046 29.4238 11.458 29.0505 11.066C28.6865 10.6647 28.1778 10.464 27.5245 10.464C26.8338 10.464 26.2785 10.6787 25.8585 11.108C25.4478 11.528 25.2425 12.1533 25.2425 12.984V18.5H22.9745V8.7H25.1725V9.89C25.5085 9.442 25.9285 9.106 26.4325 8.882C26.9365 8.64867 27.5152 8.532 28.1685 8.532Z" fill="white"/>
        </svg>
    )
    
}

export default Icon