const stages = [
    {
      quarter: "Q3",
      year: 2024,
      done: true,
      text: "Architecture development, design drawing, creation of the first version of the application",
    },
    {
      quarter: "Q4",
      year: 2024,
      done: true,
      text: "Launching the app and working with marketing",
    },
    {
      quarter: "Q1",
      year: 2025,
      text: "Attracting investors. Investment Rounds",
    },
    {
      quarter: "Q2",
      year: 2025,
      text: "Preparing for TGE, airdrop, listing",
    },
    {
      quarter: "Q3",
      year: 2025,
      text: "Coming soon...",
    },
  ];
  
  export default stages