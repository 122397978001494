export default (price?: string | number): string => {
  // Преобразуем входное значение в число
  const priceNum = Number(price || 0);

  // Если число больше или равно 1000, делаем сокращение
  if (priceNum >= 1000) {
    const units = ['k', 'M', 'B']; // k = тысячи, M = миллионы, B = миллиарды
    let unitIndex = -1;
    let num = priceNum;

    // Делим на 1000 до тех пор, пока не получим подходящее число
    while (num >= 1000 && unitIndex < units.length - 1) {
      num /= 1000;
      unitIndex++;
    }

    // Округляем до одного знака после запятой, если нужно
    const roundedNum = Math.round(num * 10) / 10;

    // Возвращаем округленное число с соответствующим суффиксом
    return `${roundedNum} ${units[unitIndex]}`;
  }

  // Если число меньше 1000, продолжаем обычное форматирование
  const roundedPrice = Math.round(priceNum * 100) / 100;
  const priceStr = roundedPrice.toString();
  const [integerPart, decimalPart] = priceStr.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};
