import React, { useContext, useState } from "react";
import "./Friends.css";
import PageWrapper from "UI/PageWrapper";
import coinImg from "assets/images/coin.png";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import FriendCard from "./FriendCard";
import Button from "UI/Button";
import Icon from "UI/Icon";
import tg from "utils/tg";
import { motion } from "framer-motion";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';

const Friends = () => {
  const [copy, setcopy] = useState(false)
  const {user} = useContext(appContext)
  const copyShareLink = (e:React.MouseEvent) => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }

  return (
    <>
    <PageWrapper className="friends-page blured-page">
      <h1>friends</h1>
      <div className="friends-reward-info">
        <div className="friends-reward-info-block">
          <img src={coinImg} alt="" />
          <div className="reward-info-block-text">
            <h5>+0.2</h5>
            <p>for one new friend</p>
          </div>
        </div>
        <div className="friends-reward-info-block">
          <img src={coinImg} alt="" />
          <div className="reward-info-block-text">
            <h5>+1</h5>
            <p>for one new 
            TG Premium friend</p>
          </div>
        </div>
      </div>
      <div className="friends-list-top">
        <p>Friends ({user?.friends}):</p>
        <div className="from-friends">
          <img src={coinImg} alt="" />
          <div className="reward-info-block-text">
            <h5>
              +
              {formatNumber(
                user?.friends_list.reduce((summ, { coins }) => summ + coins, 0)
              )}
            </h5>
            <p> from your friends</p>
          </div>
        </div>
      </div>
      <ul className="friends-list">
       {user?.friends_list.map((f,i) => <FriendCard key={i} friend={f}/>)}
      </ul>
     
    </PageWrapper>
    <motion.div
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}
    className="invite-friend-buttons">
        <Button className="invite-btn" onClick={TelegramShare}>
        Invite a friend
        </Button>
        <Button onClick={copyShareLink}>
        <Icon icon={copy ? 'done' : 'copy'}/>
        </Button>
      </motion.div>
    </>
  );
};

export default Friends;
